<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadLandContracts(true)" />
                </div>
            </div>
            <el-table :data="landContracts.data" class="mt-2">
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="partnerName" label="土地来源方" sortable />
                <el-table-column prop="contractArea" label="合同面积(亩)" sortable width="140" />
                <el-table-column prop="contractAmount" label="合同金额(元)" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditLandContractDialog(scope.row)">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="landContracts.totalRecords"
                :current-page="landContractsPagination.currentPage" :page-size="landContractsPagination.pageSize"
                class="mt-3" @current-change="landContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditLandContractDialogVisible" title="审核" :close-on-click-modal="false" width="960px"
            top="0px">
            <iframe v-if="auditLandContractDialogVisible" :src="'/#/land-contract/detail?id='+landContract.id"
                frameborder="0" class="d-block border border-primary rounded w-100"
                style="height: calc(100vh - 280px);"></iframe>

            <el-form class="mt-2">
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="success" @click="auditLandContract(true)">审核通过</el-button>
                <el-button type="danger" @click="auditLandContract(false)">审核退回</el-button>
                <el-button @click="auditLandContractDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                landContracts: {},
                landContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                landContract: {},
                auditRemark: null,
                auditLandContractDialogVisible: false,
            };
        },
        methods: {
            async loadLandContracts(resetPage = false) {
                if (resetPage) {
                    this.landContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/LandContract/GetLandContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 2,
                        key: this.key,
                        pageIndex: this.landContractsPagination.currentPage - 1,
                        pageSize: this.landContractsPagination.pageSize,
                    }
                });
                this.landContracts = response.data;
            },
            landContractsPaginationCurrentChange(page) {
                this.landContractsPagination.currentPage = page;
                this.loadLandContracts();
            },
            showAuditLandContractDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.auditRemark = null;
                this.auditLandContractDialogVisible = true;
            },
            async auditLandContract(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/LandContract/AuditLandContract', {
                    id: this.landContract.id,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditLandContractDialogVisible = false;
                this.loadLandContracts();
            },
        },
        created() {
            this.loadLandContracts();
        },
    };
</script>